<template>
  <div class="titleTop">
    <div class="titleTopLeft">
      <router-link to="/index">
        <div class="titleTopLeftImg">
          <img src="../assets/img/mzlogo.png" alt="" />
          <!-- <img  src="../assets/img/gyshop.png" alt="" /> -->
        </div>
      </router-link>

      <div class="tabBox">
        <el-tabs v-model="activeName" @tab-click="handleClickTop">
          <el-tab-pane label="患者病历" name="first"></el-tab-pane>
          <el-tab-pane label="患者列表" name="second"></el-tab-pane>
          <el-tab-pane label="个人常用处方" name="third"></el-tab-pane>
          <el-tab-pane label="处方管理" name="fourth"></el-tab-pane>
          <el-tab-pane label="订单管理" name="five"></el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="titleTopRight">
      <router-link to="/index">
        <div><i class="el-icon-discount"></i>首页</div>
      </router-link>

      <!-- <div><i class="el-icon-s-opportunity"></i>相关教程</div> -->
      <router-link to="/personal/index">
        <div>个人中心</div>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    activeNameCheck: String,
  },
  data() {
    return {
      activeName: "1",
    };
  },
  created() {
    this.activeName = this.activeNameCheck;
  },
  methods: {
    handleClickTop(e, e1) {
      if (e.label == "患者病历") {
        this.$router.push({
          name: "interrogationIndex",
        });
      }
      if (e.label == "患者列表") {
        this.$router.push({
          name: "intPatientList",
        });
      }
      if (e.label == "个人常用处方") {
        this.$router.push({
          name: "preintPatientList",
        });
      }
      if (e.label == "处方管理") {
        this.$router.push({
          name: "PremangePatientList",
        });
      }
      if (e.label == "订单管理") {
        this.$router.push({
          name: "orderList",
        });
      }
    },
  },
};
</script>
<style scoped>
.titleTop {
  padding: 0px 20px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.titleTopLeft {
  width: 85%;
  display: flex;
  align-items: center;
}
.titleTopLeftImg {
  width: 123px;
  height: 24px;
}
/* .titleTopLeftImg  {
  margin-left: 10px;
  width: 91px;
  height: 35px;
}
.titleTopLeftImg img {
  width: 100%;
  height: 100%;
} */
.tabBox {
  padding-left: 12%;
  margin-top: 10px;
}
.el-tabs__item {
  height: 60px;
  color: white !important;
  text-align: center;
  padding-top: 10px;
  font-size: 25px !important;
  background-color: #00afb5 !important;
  border: none !important;
}
.is-active {
  color: white !important;
  background-color: #00afb5 !important;
}
::v-deep .el-tabs__nav-wrap::after {
  background-color: #ffffff !important;
}
.titleTopRight {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #01C2AC;
}
.titleTopRight div {
  margin-left: 10px;
  color: #01C2AC;
}
::v-deep .el-tabs__active-bar{
  width: 0 !important;
}

</style>
